import { render, staticRenderFns } from "./q-school.vue?vue&type=template&id=720411c6&scoped=true&"
import script from "./q-school.vue?vue&type=script&lang=js&"
export * from "./q-school.vue?vue&type=script&lang=js&"
import style0 from "./q-school.vue?vue&type=style&index=0&id=720411c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720411c6",
  null
  
)

export default component.exports