var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"statPad",attrs:{"lg":"6","md":"12"}},[_c('div',[_c('b-row',{staticClass:"statRank"},[_c('b-col',{staticClass:"outerBox",attrs:{"lg":"4","sm":"4"}},[_c('div',{staticClass:"statImgBack"},[(_vm.didLoad)?_c('b-img',{staticClass:"playerImg",attrs:{"src":_vm.config.VUE_APP_PLAYER_IMAGE + 'media/photos/' +
                  _vm.statdata.oom.oom_entry[0].playerno + '.jpg'},on:{"error":_vm.pictureLoadingError}}):_c('b-img',{staticClass:"dummyImage",attrs:{"src":_vm.backupSrc}}),_c('div',{staticClass:"rankOne"},[_vm._v(" Rank "+_vm._s(_vm.statdata.oom.oom_entry[0].pos)+" ")])],1)]),_c('b-col',{staticClass:"rOneInfo",attrs:{"lg":"8","sm":"8"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.statdata.oom.oom_entry[0].forename)+" "+_vm._s(_vm.statdata.oom.oom_entry[0].surname)+" "),_c('span',[_c('img',{staticClass:"flag rOneFlag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL +
                      _vm.statdata.oom.oom_entry[0].nationality))}})])]),_c('h3',{staticClass:"sPoints"},[_vm._v(" "+_vm._s(_vm.statdata.oom.oom_entry[0].oom_value)+" ")]),_c('h3',{staticClass:"rTitle"},[_vm._v(" "+_vm._s(_vm.statdata.full_name)+" ")]),_c('h3',{staticClass:"playerInfo"},[_c('b-nav-item',{staticClass:"playerLink",attrs:{"to":'/player-profiles/' + _vm.statdata.oom.oom_entry[0].playerno + '/'}},[_vm._v(" More about this player "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'chevron-right']}})],1)],1)])],1)],1)]),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-row',[_c('b-col',{staticClass:"fullRank"},[_c('b-nav-item',{staticClass:"fullLink",attrs:{"to":{
              name: 'fullStats',
              query: { 
                id: _vm.statdata.season_code, 
                full: false,
                statdata: _vm.statdata.oom_code
              },
            }}},[_vm._v(" View Full Ranking "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'chevron-right']}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"blockList"},[_c('h3',{staticClass:"rTag"},[_vm._v(" "+_vm._s(_vm.statdata.full_name)+" ")])]),_c('table',[_c('thead',[_c('tr',[_c('th'),_c('th'),_c('th'),_c('th')])]),_c('tbody',_vm._l((_vm.statdata.oom.oom_entry.slice(0, 4)),function(sTable,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(sTable.pos)+" ")]),_c('td',[_c('b-nav-item',{staticClass:"Stable-name",attrs:{"to":'/player-profiles/' + sTable.playerno + '/'}},[_vm._v(_vm._s(sTable.name))])],1),_c('td',[_c('span',[_c('img',{staticClass:"flag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL +
                          sTable.nationality))}})])]),_c('td',{staticClass:"oomValue"},[_vm._v(" "+_vm._s(sTable.oom_value)+" ")])])}),0)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }