<template>
  <div id="hbh">
    <div class="main">
      <b-row class="row">
        <b-col class="ColSec">
          <h2 class="h2data">{{ data.full_name }} - {{ data.round_head }}
            <br>
          </h2>
        </b-col>
        <b-col class="ColSec">
          <div class="sponLogo">
            <i v-b-toggle.collapse-1 class="fa-solid fa-circle-info"></i>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse id="collapse-1" class="mt-2 informationCLass">
      <Information 
        :data="data"
        :previousData="previousData"
        :home="home"
        :title='title'
        :config="config"
        :team="team"
        :match="match"
        :code="data.code"
        :report="report"
        :live="live"
        :season="data.season"
        :takeover="takeover"
        :bbTeamEvent="data.bball_team"
        :roundsplayed="data.rounds_played"
        :showGender="showGender"
        :Stableford="Stableford"
        :rts="data.rts[0]"
      />
    </b-collapse>
    <div class="table-responsive">
      <table class="tableMob">
        <thead>
          <tr
            class="title"
            :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
          >
            <th class="CP"></th>
            <th class="CN"></th>
            <th class="CF"></th>
            <th class="CP"></th>
            <th class="CP" v-if="roundsPlayer > 1"></th>
            <th class="CP" v-if="data.multi_course === 'Y'"></th>
            <th class="CP">
              1
            </th>
            <th class="CP">
              2
            </th>
            <th class="CP">
              3
            </th>
            <th class="CP">
              4
            </th>
            <th class="CP">
              5
            </th>
            <th class="CP">
              6
            </th>
            <th class="CP">
              7
            </th>
            <th class="CP">
              8
            </th>
            <th class="CP">
              9
            </th>
            <th class="CP">
              Out
            </th>
            <th class="CP">
              10
            </th>
            <th class="CP">
              11
            </th>
            <th class="CP">
              12
            </th>
            <th class="CP">
              13
            </th>
            <th class="CP">
              14
            </th>
            <th class="CP">
              15
            </th>
            <th class="CP">
              16
            </th>
            <th class="CP">
              17
            </th>
            <th class="CP">
              18
            </th>
            <th class="CP">
              IN
            </th>
            <th class="CP">
              TOT
            </th>
          </tr>
          <template v-if="data.multi_course === 'Y'">
            <tr
              :class="multi.course_X_colour + 'row'"
              v-for="(multi, index) in data.courses.courses_entry"
              :key="index"
            >
              <th class="CP">
                {{ multi.course_X_id }}
              </th>
              <th class="CN" style="text-align:left">
                <span v-if="index === 1">
                  Player Name
                </span>
              </th>
              <th class="CF" v-if="data.multi_course === 'Y'">
                <span v-if="index === 1">
                  Crs
                </span>
              </th>
              <th class="CF">
                <span v-if="index === 1">
                  Nat.
                </span>
              </th>
              <th class="CP" v-if="roundsPlayer > 1">
                <span v-if="index === 1">
                  Tot
                </span>
              </th>
              <th class="CP">
                <span v-if="index === 1">
                  R{{data.pft_round}}
                </span>
              </th>
              <th
                class="CP"
                v-for="(course, index) in multi.course_X_par
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ course }}
              </th>
              <th class="CP">
                {{ multi.course_X_out_par.slice(1) }}
              </th>
              <th
                class="CP"
                v-for="(course, index) in multi.course_X_par
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ course }}
              </th>
              <th class="CP">
                {{ multi.course_X_in_par.slice(1) }}
              </th>
              <th class="CP">
                {{ multi.course_X_total_par }}
              </th>
            </tr>
          </template>
          <tr
            v-else
            class="title"
            :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
          >
            <th class="CP">
              Pos
            </th>
            <th class="CN" style="text-align:left">
              Player Name
            </th>
            <th class="CF" v-if="data.multi_course === 'Y'">
              Crs
            </th>
            <th class="CF">
              Nat.
            </th>
            <th class="CT" v-if="roundsPlayer > 1">
              Tot
            </th>
            <th class="CV">
              R{{data.pft_round}}
            </th>
            <th
              class="CS"
              v-for="(course, index) in data.course_par.split(',').slice(0, 9)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th class="CS">
              {{ data.course_out_par.slice(1) }}
            </th>
            <th
              class="CS"
              v-for="(course, index) in data.course_par.split(',').slice(9, 18)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th class="CS">
              {{ data.course_in_par.slice(1) }}
            </th>
            <th class="CS">
              {{ data.course_total_par }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="RT"
            v-for="(playerData, index) in data.scores.scores_entry"
            :key="index + Math.random()"
          >
            <td v-if="playerData.pos !== 'CUT'">
              <span
                v-if="
                  index !== 0
                "
                class="CP pos"
              >
                {{ playerData.tied +  playerData.pos }}
              </span>
              <span v-else-if="index === 0" class="CP pos">
                {{ playerData.tied +  playerData.pos }}
              </span>
              <span v-else></span>
            </td>

            <td
              style="text-align:left"
              v-if="playerData.pos !== 'CUT'"
              :title="playerData.member_no"
              :class="{
                'text-up': playerData.posChang == 'up',
                'text-down': playerData.posChang == 'down',
              }"
            >
              <a
                class="hbhName"
                :href="
                  '/player-profiles/' + playerData.id + '/'
                "
                style="color: #000;"
              >
                {{ playerData.name }}</a
              > 
              <span style="margin-left: 10px;" v-if="playerData.pro_ind === 'Am'">(a)</span>
            </td>
            <td v-if="playerData.pos !== 'CUT' && data.multi_course === 'Y'">
              <span>
                <span :class="playerData['course_colour_R' + data.pft_round]">
                  •
                </span>
              </span>
            </td>
            <td
              v-if="playerData.pos !== 'CUT'"
              class="CF"
              :title="playerData.nationality"
            >
              <span>
                <img
                  class="flag"
                  :src="
                    (config.VUE_APP_FLAG_URL + playerData.nationality)
                      | lowercase
                  "
                />
              </span>
            </td>
            <template v-if="roundsPlayer > 1">
            <td v-if="playerData.pos !== 'CUT'" class="CT">
              <span v-if="playerData.vspar < 0" :class="up">
                {{ playerData.vspar }}
              </span>
              <span v-else-if="playerData.vspar > 0" :class="down">
                {{ playerData.vspar }}
              </span>
              <span v-else>
                {{ playerData.vspar }}
              </span>
            </td>
            </template>
            <td class="RoundCol" v-if="playerData.pos !== 'CUT'">
              <span v-if="playerData['vspar_R' + roundsPlayer] < 0" :class="up">
                {{ playerData["vspar_R" + roundsPlayer] }}
              </span>
              <span
                v-else-if="playerData['vspar_R' + roundsPlayer] > 0"
                :class="down"
              >
                {{ playerData["vspar_R" + roundsPlayer] }}
              </span>
              <span v-else>
                {{ playerData["vspar_R" + roundsPlayer] }}
              </span>
            </td>
            <td class="cut" colspan="30" v-if="playerData.pos === 'CUT'">
              {{ playerData.cut_round }}
            </td>
            <template v-if="playerData.pos !== 'CUT'">
              <td
                class="CS"
                v-for="(r1, index) in (
                  playerData['hole_scores_R' + roundsPlayer] || ''
                )
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
                :class="{'twoUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -2, 
                'oneUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -1,
                'twoOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] > 1,
                'oneOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == 1,
                } 
                "
              >
                <span
                  v-if="(playerData['strokes_R' + roundsPlayer] || '').split(',')[0] == (index + 1)"
                  class="roundStroke"
                >{{ (playerData['strokes_R' + roundsPlayer] || '').split(',')[1] }}</span>
                <span
                  class="twoUnder"
                  v-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                      ','
                    )[index] == -2
                  "
                >
                  {{ r1 }}
                </span>
                <span
                  class="oneUnder"
                  v-else-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                      ','
                    )[index] == -1
                  "
                >
                  {{ r1 }}
                </span>
                <span
                  class="oneOver"
                  v-else-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                      ','
                    )[index] == 1
                  "
                >
                  {{ r1 }}
                </span>
                <span
                  class="twoOver"
                  v-else-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                      ','
                    )[index] > 1
                  "
                >
                  {{ r1 }}
                </span>
                <span v-else>
                  {{ r1 }}
                </span>
              </td>
              <td class="CS">
                <span
                  v-if="
                    sum(
                      (playerData['hole_scores_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(0, 9)
                    ) > data.course_out_par
                  "
                  class="down"
                >
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(0, 9)
                      )
                    }} 
                  </span>
                </span>
                <span
                  v-else-if="
                    sum(
                      (playerData['hole_scores_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(0, 9)
                    ) < data.course_out_par
                  "
                  class="up"
                >
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(0, 9)
                      )
                    }}
                  </span>
                </span>
                <span v-else>
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(0, 9)
                      )
                    }}
                  </span>
                </span>
              </td>
              <td
                class="CS"
                v-for="(r1, index) in (
                  playerData['hole_scores_R' + roundsPlayer] || ''
                )
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
                :class="{'twoUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -2, 
                'oneUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -1,
                'twoOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] > 1,
                'oneOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == 1
                } "
              >
                <span 
                  v-if="(playerData['strokes_R' + roundsPlayer] || '').split(',')[0] == (index + 10)" 
                  class="roundStroke"
                >{{ (playerData['strokes_R' + roundsPlayer] || '').split(',')[1] }}</span>
                <span
                  class="twoUnder"
                  v-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '')
                      .split(',')
                      .slice(9, 18)[index] == -2
                  "
                >
                  {{ r1 }}
                </span>
                <span
                  class="oneUnder"
                  v-else-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '')
                      .split(',')
                      .slice(9, 18)[index] == -1
                  "
                >
                  {{ r1 }}
                </span>
                <span
                  class="oneOver"
                  v-else-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '')
                      .split(',')
                      .slice(9, 18)[index] == 1
                  "
                >
                  {{ r1 }}
                </span>
                <span
                  class="twoOver"
                  v-else-if="
                    (playerData['hole_vspars_R' + roundsPlayer] || '')
                      .split(',')
                      .slice(9, 18)[index] > 1
                  "
                >
                  {{ r1 }}
                </span>
                <span v-else>
                  {{ r1 }}
                </span>
              </td>
              <td class="CS">
                <span
                  v-if="
                    sum(
                      (playerData['hole_scores_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)
                    ) > data.course_in_par
                  "
                  class="down"
                >
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(9, 18)
                      )
                    }}
                  </span>
                </span>
                <span
                  v-else-if="
                    sum(
                      (playerData['hole_scores_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)
                    ) < data.course_in_par
                  "
                  class="up"
                >
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(9, 18)
                      )
                    }}
                  </span>
                </span>
                <span v-else>
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(9, 18)
                      )
                    }}
                  </span>
                </span>
              </td>
              <td class="CS">
                <span
                  v-if="
                    sum(
                      (playerData['hole_scores_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(0, 18)
                    ) > data.course_total_par
                  "
                  class="down"
                >
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(0, 18)
                      )
                    }}
                  </span>
                </span>
                <span
                  v-else-if="
                    sum(
                      (playerData['hole_scores_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(0, 18)
                    ) < data.course_total_par
                  "
                  class="up"
                >
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(0, 18)
                      )
                    }}
                  </span>
                </span>
                <span v-else>
                  <span v-if="playerData.holes === '18' || playerData.holes === F">
                    {{
                      sum(
                        (playerData["hole_scores_R" + roundsPlayer] || "")
                          .split(",")
                          .slice(0, 18)
                      )
                    }}
                  </span>
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Information from "@/components/information.vue";
export default {
  name: "hbh",
  props: ["tabs", "data", "scores", "previousData", "develop", "config", "team", "bballlTeam", "showGender"],
  components: {
    Information
  },
  data() {
    return {
      up: "up",
      down: "down",
      CS: "CS",
      CV: "CV",
      CT: "CT",
      CN: "CN",
      CP: "CP",
      CF: "CF"
    };
  },
  computed: {
    roundsPlayer: function() {
      return parseInt(this.data.pft_round);
    },
    julianTime: function() {
     return  this.jTime();
    }
  },
  watch: {
    scores: {
      immediate: true,
      handler(newValue, oldValue) {
        newValue.forEach((value1, key1) => {
          oldValue.forEach((value2, key2) => {
            if (value1.id === value2.id) {
              if (key1 < key2) {
                value1.posChang = "up";
              } else if (key1 > key2) {
                value1.posChang = "down";
              }
            }
          });
        });

        console.log(newValue);
      },
      deep: true
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    jTime: function() {
      var jTime = this.data.last_scores_update_julian;
      this.jTime = Number(jTime);
      return this.jTime;
    },
    sum: function(nums) {
      let result = 0;
      nums.forEach(function(n) {
        result += n * 1;
      });
      if (result == 0) {
        result = ''
      } else {
        return result; 
      }
    }
  }
};
</script>

<style scoped>
span.roundStroke {
  color: #3c9933 !important;
  font-weight: bold;
  font-style: italic; 
  font-size: 12px;
  background-color: transparent;
}
::v-deep td {
  font-size: 1rem!important;
}
a.hbhName {
  padding-left: 12px;
}
.ColSec {
  margin: 0;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
::v-deep .informationCLass {
  margin-top: 0px!important;
}
td.RoundCol {
  /* border: 1px solid #000; */
}
span.none {
  display: none;
}
a.playerName-hbh {
  color: #000;
}
.Pname-hbh > a {
  color: #000;
}
.Pname-hbh {
  text-align: left;
}
.Pname {
  text-align: left;
}
#hbh{
  /* padding-top: 2%; */
  padding-bottom: 2%;
}

.main>.row{
  color: #fff;
  /* padding: 15px 0 15px 10px; */
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #7ba639;
  margin-left: 0px;
  margin-right: 0px;
}
.h2data {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize; 
}
.cell {
  width: 17%;
  font-size: 1rem;
  padding: .75rem;
}

.flag {
  width: 25px;
  box-shadow: 0 0 3px #555;
}

.title {
  background-color: #95c93d;
  color: white;
}
::v-deep tr > th {
  font-size: 10pt;
}
::v-deep th {
  color: white;
  position: sticky;
  top: 0;
  background-color: #7ba639; 
  font-weight: 500;
}
th:first-child{
  padding-left: 10px;
}
::v-deep tr:nth-child(even) {
  background-color: rgba(0,0,0,.03);
}

td{
  /* padding: .75rem; */
  font-size: 10pt;
}
th{
  font-size: 12pt;
  padding: .75rem;
}

.B {
  color: #333333 !important;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}
.R {
  color: #bb0000 !important;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}

.Brow {
  background-color: #333333 !important;
  color: white;
  font-size: 16px;
   border-bottom: 3px solid #333333;
}
.Rrow {
  background-color: #bb0000 !important;
  color: white;
  font-size: 16px;
  border-bottom: 3px solid #bb0000;
}


.title {
  text-align: center;
  background-color: #133F7B!important;
  color: white;
}


.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}

.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
td.oneUnder > span {
  color: #fff !important;
  background: #a52a2a !important;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

td.twoUnder > span {
  color: #fff !important;
  background: #3c9933 !important;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

td.oneOver > span{
  color: #fff !important;
  background: #1e90ff!important;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

td.twoOver > span {
  color: #fff !important;
  background-color: #e07020;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.RT {
  /* border: 1px solid black; */
  text-align: center;
  border-left: 0px;
}
.CP {
  width: 2%;
  text-align: center;
}
.CN {
  width: 20%;
}
.CF {
  width: 5%;
  text-align: center;
}
.CT {
  width: 3%;
  /* border: 1px solid black; */
  text-align: center;
}
.CV {
  width: 3%;
  /* border: 1px solid black; */
  text-align: center;
}
.CS {
  /* border: 1px solid black; */
  text-align: center;
  width: 3%;
  padding-top: 10px;
  padding-bottom: 10px;
}
tr.title > .CT {
  width: 3%;
  /* border-bottom: 1px solid black; */
  border-top: 1px solid #7ba639;
  border-left: 1px solid #7ba639;
  border-right: 1px solid #7ba639;
  text-align: center;
}
tr.title > .CV {
  width: 3%;
  /* border-bottom: 1px solid black; */
  border-top: 1px solid #7ba639;
  border-left: 1px solid #7ba639;
  border-right: 1px solid #7ba639;
  text-align: center;
}
tr.title > .CS {
  /* border-bottom: 1px solid black; */
  border-top: 1px solid #7ba639;
  border-left: 1px solid #7ba639;
  border-right: 1px solid #7ba639;
  text-align: center;
  width: 3%;
  
}
td.CS{
  color: black;
}
td>span{
  color: black;
}
.cut>img {
  width: 100%;
}
.cut{
  padding: 0;
  background: #aa0003;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}
@media only screen and (max-width: 480px) {
  .tableMob {
    width: 275%;
  }
}
</style>
