<template>
  <div id="results" :class="{ home: home === true }">
    <div v-if="home === true">
      <b-container class="homeFullWidth">
        <b-row class="homeLeader">
          <b-col>
            <b-img class="courseLogo" :src="data.event_sponsor_logo"></b-img>
            <h3 class="homeHead">{{ data.short_name }} {{ liveOn }} - {{ title }}</h3>
            <div class="homecourseDates">{{data.course_dates}}</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-row v-else class="title">
      <b-col>
        <h3>
          {{ data.full_name }} {{ title
          }}
        </h3>
      </b-col>
      <b-col
      >
        <div class="sponLogo">
          <i v-b-toggle.collapse-1 class="fa-solid fa-circle-info"></i>
        </div>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2 informationCLass">
      <Information 
        :data="data"
        :previousData="previousData"
        :home="home"
        :title='title'
        :config="config"
        :team="team"
        :match="match"
        :code="data.code"
        :report="report"
        :live="live"
        :season="data.season"
        :takeover="takeover"
        :bbTeamEvent="data.bball_team"
        :roundsplayed="data.rounds_played"
        :showGender="showGender"
        :Stableford="Stableford"
      />
    </b-collapse>
    <table>
      <thead>
        <tr v-if="home === false">
          <template> </template>
          <template v-if="tablePick === 'Final'">
            <th>
              Pos
            </th>

            <th class="PadLeft">
              Players(s)
            </th>
            <th class="HideMovResult">
              Nat.
            </th>
            <template v-if="data.show_winnings == 'B'">
              <th style="padding-left: 10px">Winnings</th>
            </template>
            <template v-else-if="data.show_winnings !== 'B'">
              <th>
                <span style="padding-left: 10px" v-show="data.show_winnings == 'Y'">Winnings</span>
                <span v-show="data.show_winnings == 'P'">
                  <span v-hide="data.show_points_name.length > 0">Points</span>
                  <span v-show="data.show_points_name.length > 0">{{ data.show_points_name }}</span>
                </span>
              </th>
            </template>
            <template v-if="data.show_winnings == 'B' && data.have_points == 'Y'">
              <th>{{ data.show_points_name }}</th>
            </template>
            <template v-if="match === 'N'">
              <th class="PadLeft">
                Par
              </th>
              <th class="PadLeft">
                Score
              </th>
              <th
                v-for="(i, roundIt) in range(1, roundsPlayed)"
                :key="roundIt"
                class="PadLeft HideMovResult"
              >
                R{{ i }}
              </th>
              <th v-if="data.have_oom == 'Y'" class="hideOnMob">
                OOM Points
              </th>
              <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'"></th>
            </template>
            <th></th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr
            @click.stop="rowClicked(index)"
            :key="index"
            v-bind:class="{
              'homeTd-size': home === true,
              'homeTd-sizehover': home === true,
              drawHome: home === true && tablePick === 'Draw',
              'cut' : tabledata.pos === 'CUT'
            }"
          >
            <template v-if="tablePick === 'Final'" >
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.pos }}
              </td>
              <td
                class="posnum"
                v-if="
                  tabledata.pos !== 'CUT' &&
                  index !== 0
                "
              >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0">
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td v-else-if="tabledata.pos !== 'CUT'" class="noPos"><span>.</span></td>

              <td
                v-if="tabledata.playerno !== 'Cat' && tabledata.pos !== 'CUT'"
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
                class="PlayerName"
              >
                  {{ tabledata.name }}
                <span v-if="tabledata.sponsored === 'Y' && home !== true">
                  <img
                    class="titliestLogo"
                    src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                  />
                </span>
              </td>
              <td
                class="mobHide HideMovResult"
                v-if="tabledata.pos !== 'CUT' && home !== true"
              >
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <td v-if="tabledata.playerno !== 'Cat' && tabledata.pos !== 'CUT'">
               <span v-if="data.show_winnings == 'B' || data.show_winnings == 'Y'"> {{tabledata.winnings}}</span>
              </td>
              <td v-if="tabledata.playerno !== 'Cat' && tabledata.pos !== 'CUT' && home !== true" class="hideOnMob">
                <span v-if="home !== true">
                  <template v-if="data.show_winnings !== 'B'">
                    <span v-if="data.show_winnings == 'Y'">{{tabledata.winnings}}</span>
                    <span v-if="data.show_winnings == 'P'">{{tabledata.winnings}}</span>
                  </template>
                  <template v-else-if="data.show_winnings == 'B' && data.have_points == 'Y'">
                    {{tabledata.points_both}}
                  </template>
                  <template v-else>
                    <span>{{tabledata.winnings}}</span>
                  </template>
                </span>
                <span v-if="home === true">{{ tabledata.score }}</span>
              </td>
              <template v-if="match === 'N' && tabledata.playerno !== 'Cat'">
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.vspar }}
                </td>
                <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                  {{ tabledata.score }}
                </td>
                <td
                  v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                  class="down"
                >
                  {{ tabledata.score }}
                </td>
                <td v-else-if="roundsPlayed > 1 && tabledata.pos !== 'CUT'">
                  {{ tabledata.score }}
                </td>
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="hideOnMob"
                  v-show="tabledata.pos !== 'CUT' && home !== true"
                >
                <span
                  v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                  class="up"
                  >{{ tabledata.score }}</span
                >
                <span
                  v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                  class="down"
                  >{{ tabledata.score }}</span
                >
                <span v-else-if="roundsPlayed === 1">{{
                  tabledata.score
                }}</span>
                  <span v-else-if="tabledata['score_R' + i] < data.course_total_par.slice(1)" :class="up">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['score_R' + i] > data.course_total_par.slice(1)"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else>{{ tabledata["score_R" + i] }}</span>
                </td>  
              </template>
              <template v-if="home != true">
                <td v-if="data.have_oom == 'Y' && tabledata.pos !== 'CUT'">{{tabledata.points}}</td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    tabledata.pos !== 'CUT'
                "
              >
                <a :href="'/player-profiles/' + tabledata.member_no" v-if="tabledata.profile == 'Y'" target="_blank">
                  <span class="iconRight">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fa', 'chevron-right']"
                    />
                  </span>
                </a>
              </td>
            </template>
          </tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="12">
              <PlayerReportCard
                  :data="data"
                  :playerCardData="playerCardData"
                  :config="config"
                  :actualRound="data.pft_round"
                  :roundsPlayed="roundsplayed"
                  :scoreType="data.scores_type"
                  :team="team"
                  :courses="courses"
                  :tmparams="tmparams"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="home === true">
      <b-container class="homeFullWidth">
        <b-row class="homeLeaderHome">
          <b-col>
            <b-nav-item
              class="fullNav"
              :to="{
                name: 'reports-page',
                query: {
                  url: this.report,
                  id: season,
                  code: code,
                  title: title
                }
              }"
            >
              <h3 class="FullLeaderboard">Full Leaderboard</h3>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import PlayerReportCard from "@/components/playerReportCard.vue";
import PlayerReportCard from "@/components/orginalPlayerCard.vue";
import Information from "@/components/information.vue";
export default {
  name: "results",
  props: [
    "data",
    "roundsplayed",
    "Stableford",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "showGender",
    "tmparams",
    "roundsP"
  ],
  components: { 
    PlayerReportCard,
    Information
  },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.home == true) {
        // console.log("TRUE");
        this.clickedRow = this.reportTitle;
      } else {
        if (
          (this.tablePick === "Final" && this.clickedRow !== index)
        )
          this.clickedRow = index;
        else this.clickedRow = -1;
      }
    },
    playerCard: function(event) {
      console.log("in player card")
      console.log()
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.tmparams.tm_params.season_code +
              "/" +
              this.tmparams.tm_params.season_code +
              "-" +
              this.tmparams.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (
            this.playerCardData = response.data,
            console.log("this.playerCardData"),
            console.log(this.playerCardData)
          ))
      );
    },
  },
  computed: {
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
        picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        )
          reportData = this.data.result.result_entry;
        return reportData;
      } else {
         if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        )
          reportData = this.data.result.result_entry.slice(0, 4);
        return reportData;
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
/* Home Styles */
.homeFullWidth {
  max-width: 100%;
}
td.PlayerName:hover {
  cursor: pointer;
  color: #7ba639;
}
.iconRight:hover {
  color: #7ba639;
}
::v-deep tr.homeTd-size.homeTd-sizehover:hover {
  background-color: #f6f5f5!important;
}
::v-deep tr:hover {
  background-color: #f6f5f5!important;
}
::v-deep .informationCLass {
  margin-top: 0px!important;
}
.hideRepresentsHome {
  display: none;
}
p.homePlayername {
  color: #95c93d;
}
td.homePlayername {
  color: #95c93d;
}
a.homePlayername {
  color: #95c93d;
}
a.homeResult {
  color: #95c93d !important;
}
th.representsText {
  text-align: left !important;
}
td.representsText {
  text-align: left !important;
}
tr.homeTd-size {
  background-color: #fff;
  color: #95c93d;
  border-top: 1px solid #95c93d !important;
  background-color: #fff!important;
}
tr.drawHome {
  border-top: 0px solid #95c93d !important;
}
tr.drawHome > td.drawPos {
  top: 0px;
}
tr.drawHome > td > a {
  color: #95c93d !important;
}
tr.homeTd-size > td {
  font-size: 10pt;
  color: #212529;
}
h3.FullLeaderboard {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
}
.natNone {
  display: none;
}
.fullNav {
  padding-top: 0 !important;
  display: block !important;
}
.fullNav > .nav-link {
  padding: 0 1rem;
  color: white;
}
.table-responsive {
  margin-bottom: 0;
}
.courseLogo {
  width: 35%;
  padding-top: 25px;
}

.homeLeader {
  text-align: center !important;
  background-color: #95c93d !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  padding-bottom: 10px!important;
}
.homeLeaderHome {
  text-align: center !important;
  background-color: #95c93d !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  /* padding-bottom: 10px!important; */
}

.homeHead {
  font-size: 11pt;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 0px;
}
.homecourseDates {
  font-size: 11pt;
  padding-bottom: 15px;
}
.homeHide {
  display: none;
}
tr:nth-child(odd) {
  background-color: white;
}

.home {
  /* width: 350px; */
  width: 300px;
  position: absolute;
  top: 30px;
  /* top: -80px;  */
  right: 30%;
}
.homeCell {
  padding: 0.75rem;
  font-size: 10pt;
}
.homeCell > a {
  color: #95c93d;
}
.posCell {
  width: 2%;
}
.scoreCell {
  width: 2%;
}
.sponsorHome {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
/* Main Styles */
th.PadLeft {
  padding-left: 10px;
}
img.titliestLogo {
  height: 12px;
  margin-bottom: 1px;
  margin-left: 10px;
}
th.playersName-score {
  padding-left: 10px;
}
td.centerTime {
  text-align: center;
}
th.Timecenter {
  text-align: center;
}
td.drawName > a {
  color: #000;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
  position: relative;
  top: 3px;
}
.title {
  text-align: center;
  background-color: #7ba639;
  color: white;
  margin-left: 0;
  margin-right: 0;
}
.title > .col > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #7ba639;
  text-align: left;
  font-weight: 400;
}
.tLogo {
  width: 65px;
  margin-top: -5px;
  margin-right: 15px;
}
td.catD {
  background: #95c93d;
  color: #fff;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  color: white !important;
  position: sticky;
  top: 0;
  background-color: #7ba639;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
  padding-bottom: 0;
  padding-top: 0;
}
/* .even {
  background-color: #c8e49a !important;
  padding-bottom: 0;
  padding-top: 0;
} */
tr:nth-child(even) {
  background-color: rgba(0,0,0,.03);
}
.titlest {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
td {
  font-size: 1rem;
  padding: 0.75rem;
}
td > a {
  /* color: #055498; */
  color: #000;
}
tr > :nth-child(2) {
  /* color: #337ab7; */
  cursor: pointer;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 10px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003!important;
  color: white!important;
}
.cut > td > span {
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .home {
    /* width: 350px; */
    width: 100% !important;
    position: inherit !important;
    top: 30px;
    /* top: -80px;  */
    right: 30%;
  }
  .home > div > div {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .home {
    position: inherit;
    width: 100%;
  }
  .homeLeader {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 480px) {
  .hideOnMob {
    display: none;
  }
  .tableMob {
    width: 275%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  ::v-deep tr > :nth-child(2) {
    font-size: 10pt;
  }
  ::v-deep th {
    font-size: 10pt;
  }
  ::v-deep td {
    font-size: 10pt;
    padding: 0.75rem;
  }
  ::v-deep .dropdown {
    width: 100%;
    padding-right: 12px;
  }
  ::v-deep .HideMovResult {
    display: none;
  }
}
</style>
