<template>
  <div>
    <b-container>
      <template v-if="this.$route.name === 'home'">
        <b-col>
          <b-container class='home'>
            <b-embed
            
              type="iframe"
              aspect="16by9"
              :src="
                'https://www.youtube.com/embed/' +
                  videos[0].snippet.resourceId.videoId
              "
              allowfullscreen
            ></b-embed>
          </b-container>
        </b-col>
      </template>
      <b-row v-else-if="this.$route.query.id === ''">
        <b-col lg="4" v-for="(vids, index) in videos" :key="index">
          <b-nav-item
            :to="{
              name: 'video',
              query: { id: vids.id },
            }"
          >
            <b-card
              :title="vids.snippet.title"
              :img-src="vids.snippet.thumbnails.high.url"
              img-alt="Image"
              img-top
              tag="article"
              class="feat"
            >
            </b-card>
                <b-img
                  class="play"
                  src="https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pga-aus/2020/02/play.png"
                ></b-img>
          </b-nav-item>
        </b-col>
      </b-row>
      <b-row v-else>
        <template v-if="this.$route.name === 'team-europe'">
          <b-col
            lg="4"
            v-for="(vids, index) in videos.slice(0, 6)"
            :key="index"
          >
            <div @click="$bvModal.show(vids.etag)">
              <b-card
                :title="vids.snippet.title.toUpperCase()"
                :img-src="vids.snippet.thumbnails.high.url"
                img-alt="Image"
                img-top
                tag="article"
                class="feat TeamEurope"
              >
                <b-img
                  class="play"
                  src="https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pga-aus/2020/02/play.png"
                ></b-img>

                <b-modal :id="vids.etag" hide-header centered hide-footer>
                  <b-embed
                    type="iframe"
                    aspect="16by9"
                    :src="
                      'https://www.youtube.com/embed/' +
                        vids.snippet.resourceId.videoId
                    "
                    allowfullscreen
                  ></b-embed>
                </b-modal>
              </b-card>
            </div>
          </b-col>
        </template>
        <template v-else>
          <b-col lg="4" v-for="(vids, index) in videos" :key="index" class="paddingYoutube">
            <span v-if="vids.snippet.title === 'Private video'"></span>
            <div v-else @click="$bvModal.show(vids.etag)">
              <b-card
                :title="vids.snippet.title"
                :img-src="vids.snippet.thumbnails.high.url"
                img-alt="Image"
                img-top
                tag="article"
                class="feat"
              >
                <b-img
                  class="play"
                  src="https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pga-aus/2020/02/play.png"
                ></b-img>

                <b-modal :id="vids.etag" hide-header centered hide-footer>
                  <b-embed
                    type="iframe"
                    aspect="16by9"
                    :src="
                      'https://www.youtube.com/embed/' +
                        vids.snippet.resourceId.videoId
                    "
                    allowfullscreen
                  ></b-embed>
                </b-modal>
              </b-card>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "youTube",
  props: ["id"],
  data() {
    return {
      videos: [],
    };
  },
  computed: {
    mainOrPlay(url) {
      if (this.id === "") {
        if (this.$route.name === 'team-europe') {
          url =
          "https://www.googleapis.com/youtube/v3/playlists?part=snippet&CcontentDetails&channelId=UC9NboheGgkad3FwqEIk0JlA&maxResults=25&key=AIzaSyBC_JSmE_cM7BFu4ts_i0SgPe8SfkhGkGI"
        } else {
          url = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PL54E98AD0E9604283&key=AIzaSyBPmBqZ3TEin9MAb8MVvFhOQJdUkpGV9JY'        
        }

       } else{
         if (this.$route.name === 'team-europe') {
          url = 
          "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=6&playlistId=PLfQ8d960HoQzMZ9sSqd8JspgIAXO6jXyD&key=AIzaSyBC_JSmE_cM7BFu4ts_i0SgPe8SfkhGkGI";  
         } else {
          url =
          'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PL54E98AD0E9604283&key=AIzaSyBPmBqZ3TEin9MAb8MVvFhOQJdUkpGV9JY'     
         }   
       }

      return url;
    },
  },
  mounted() {
    axios.get(this.mainOrPlay).then((response) => {
      this.videos = response.data.items;
    });
  },
};
</script>

<style scoped>
::v-deep .nav-link {
  display: block;
  padding: 0rem 0rem;
}
article.card.feat.TeamEurope {
  margin-top: 1em;
}
.TeamEurope > div > .card-title {
  background-color: rgba(24,23,151,1)!important;
  text-transform: initial;
  bottom: -23px;
}
.paddingYoutube > div > article > div > img.play {
  margin: -190px 0 0 112px;
}
.paddingYoutube {
  padding-bottom: 30px;
}
.row {
  padding-bottom: 20px;
}
.col {
  padding-bottom: 20px;
}
.card-body {
  padding: 0;
}
.card-title {
  background-color: #95c93d !important;
  position: absolute;
  width: 85%;
  right: 0px;
  bottom: -23px;
  color: #fff;
  padding: 10px;
  opacity: 0.9;
  margin-bottom: 1.5em;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
::v-deep .modal-content {
  background-color: transparent;
  border: 0;
}
::v-deep .modal-body {
  padding: 0;
}
.embed-responsive {
  width: 200%;
  margin-left: -300px;
}
.home{
  padding: 50px 0 0;
}
.home>.embed-responsive{
  width:100%;
  margin-left:auto;
}
.play {
  position: absolute;
  width: 110px;
  height: 110px;
  margin: -188px 0 0 115px;
  z-index: 11;
}
@media only screen and (max-width: 1024px) {
  .play {
    width: 90px;
    height: 90px;
    margin: -193px 0 0 59px;
  }
}
@media only screen and (max-width: 768px) {
  .play {
    display: none;
  }
  .embed-responsive {
    width: 150%;
    margin-left: -125px;
  }
  .card {
    margin: 20px 0;
  }
}
@media only screen and (max-width: 480px) {
  .embed-responsive {
    width: 100%;
    margin-left: 0;
  }
  .card {
    margin: 0px 0;
  }
  .card-title {
    background-color: #95c93d !important;
    position: absolute;
    width: 85%;
    left: 0px;
    bottom: -28px;
    color: #fff;
    padding: 10px;
    opacity: 0.9;
    margin-bottom: 2.5em;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 9pt;
    padding-bottom: 10px;
  }
}
</style>
