var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ home: _vm.home === true, takeOver: _vm.takeover == true }},[(_vm.home === true)?_c('div',[_c('b-container',{staticClass:"homeFullWidth"},[_c('b-row',{staticClass:"homeLeader"},[_c('b-col',[_c('b-img',{staticClass:"courseLogo",attrs:{"src":_vm.data.event_sponsor_logo}}),_c('h3',{staticClass:"homeHead"},[_vm._v(" "+_vm._s(_vm.data.short_name)+" "),(_vm.data.live_scoring == 'Y')?_c('span',[_vm._v(" - Live Scoring is on")]):_vm._e()]),_c('div',{staticClass:"homecourseDates"},[_vm._v(_vm._s(_vm.data.course_dates))])],1)],1)],1)],1):_c('b-row',{staticClass:"title"},[_c('b-col',[_c('h3',[_vm._v(" "+_vm._s(_vm.data.full_name)+" - "+_vm._s(_vm.title)+" ")])]),(
        _vm.tablePick === 'Score' || _vm.tablePick === 'Final' || _vm.tablePick === 'Live'
      )?_c('b-col',[_c('div',{staticClass:"sponLogo"},[_c('i',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"fa-solid fa-circle-info"})])]):_vm._e()],1),_c('b-collapse',{staticClass:"mt-2 informationCLass",attrs:{"id":"collapse-1"}},[_c('Information',{attrs:{"data":_vm.data,"previousData":_vm.previousData,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.team,"match":_vm.match,"code":_vm.data.code,"report":_vm.report,"live":_vm.live,"season":_vm.data.season,"takeover":_vm.takeover,"bbTeamEvent":_vm.data.bball_team,"roundsplayed":_vm.data.rounds_played,"showGender":_vm.showGender,"Stableford":_vm.Stableford,"rts":_vm.data.rts[0]}})],1),(this.compare.length)?_c('div',[_c('span',{staticClass:"favtextHead"},[_vm._v("My Leaderboard")])]):_vm._e(),(this.compare.length)?_c('div',[_c('Favplayer',{attrs:{"data":_vm.data,"previousData":_vm.previousData,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.team,"match":_vm.match,"code":_vm.data.code,"report":_vm.report,"live":_vm.live,"season":_vm.data.season,"takeover":_vm.takeover,"bbTeamEvent":_vm.data.bball_team,"roundsplayed":_vm.data.rounds_played,"showGender":_vm.showGender,"Stableford":_vm.Stableford,"compare":_vm.compare}})],1):_vm._e(),_c('table',[_c('thead',[(_vm.home === false)?_c('tr',[(
            (_vm.tablePick === 'Score' && _vm.data.match_play === 'N') ||
              (_vm.tablePick === 'Live' && _vm.data.match_play === 'N')
          )?[_c('th',[_vm._v(" Fav ")]),_c('th',[_vm._v(" Pos ")]),(_vm.tablePick === 'Live' && _vm.RTCDS > 0)?[_c('th',[_vm._v(" OOM ")])]:_vm._e(),_c('th',{staticClass:"playersName-score"},[_vm._v(" Player Name ")]),(_vm.data.multi_course == 'Y')?_c('th',{staticClass:"noPadLeft"},[_vm._v(" Crs ")]):_vm._e(),_c('th',{staticClass:"noPadLeft"},[_vm._v(" Nat. ")]),(
              _vm.data.score_type === 'P' ||
                (_vm.data.tt_type === 'P' && _vm.data.stableford === 'N')
            )?_c('th',{staticClass:"PadLeft"},[_vm._v(" Par ")]):_vm._e(),(
              _vm.data.score_type === 'P' ||
                (_vm.data.tt_type === 'P' && _vm.data.stableford === 'Y')
            )?_c('th',{staticClass:"PadLeft"},[_vm._v(" Points ")]):_vm._e(),(this.title.includes('Scoreboard'))?[_vm._l((_vm.range(1, _vm.roundsPlayed)),function(i,roundIt){return _c('th',{key:roundIt,staticClass:"hideOnMob"},[(_vm.roundsPlayed === 1 && _vm.tablePick === 'Score')?_c('span',[_vm._v(" Score ")]):_c('span',[_vm._v(" R"+_vm._s(i)+" ")])])}),(_vm.data.scores.scores_entry[0].score_found === 'Y')?[(_vm.roundsPlayed > 1)?_c('th',[_vm._v(" Score ")]):_vm._e()]:_vm._e(),(_vm.roundsPlayed > 1 && _vm.tablePick === 'Score')?[(
                  _vm.config.VUE_APP_PLAYER_ICON === 'Y' &&
                    _vm.data.scores.scores_entry[0].score_found === 'Y' &&
                    _vm.data.last_round_complete === 'Y'
                )?_c('th',{}):_vm._e()]:_vm._e()]:_vm._e(),(
              _vm.data.scores.scores_entry[0].score_found === 'N' ||
                _vm.data.round_head == 'Latest Scores'
            )?[_c('th',[_vm._v(" Thru ")])]:(
              (_vm.data.scores.scores_entry[0].score_found === 'Y' &&
                this.title.includes('Scores Only')) ||
                this.title.includes('Latest Scores')
            )?[_c('th',[_vm._v("Score")])]:_vm._e(),_c('th')]:_vm._e()],2):_vm._e()]),_c('tbody',[_vm._l((_vm.Eds),function(tabledata,index){return [_c('tr',{key:index,class:{
            'homeTd-size': _vm.home === true,
            'homeTd-sizehover': _vm.home === true,
            drawHome: _vm.home === true && _vm.tablePick === 'Draw'
          },on:{"click":function($event){$event.stopPropagation();return _vm.rowClicked(index)}}},[(
              (_vm.tablePick === 'Score' && _vm.data.match_play === 'N') ||
                (_vm.tablePick === 'Live' && _vm.data.match_play === 'N')
            )?[(tabledata.pos !== 'CUT' && _vm.home !== true)?_c('td',{staticClass:"wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.compare),expression:"compare"}],attrs:{"type":"checkbox","id":tabledata.member_no,"name":tabledata.member_no},domProps:{"value":tabledata,"checked":Array.isArray(_vm.compare)?_vm._i(_vm.compare,tabledata)>-1:(_vm.compare)},on:{"change":function($event){var $$a=_vm.compare,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=tabledata,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.compare=$$a.concat([$$v]))}else{$$i>-1&&(_vm.compare=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.compare=$$c}}}}),_c('label',{attrs:{"for":tabledata.member_no}})]):_vm._e(),(tabledata.pos === 'CUT')?_c('td',{staticClass:"cut",attrs:{"colspan":"11"}},[_vm._v(" "+_vm._s(tabledata.cut_round)+" ")]):(index !== 0 && tabledata.pos !== 'CUT')?_c('td',{staticClass:"posnum"},[(tabledata.pos.length > 0)?[_c('span',[_vm._v(" "+_vm._s(tabledata.tied)+" "+_vm._s(tabledata.pos)+" ")])]:_vm._e()],2):(index === 0 && tabledata.pos !== 'CUT')?_c('td',{staticClass:"posnum"},[(tabledata.pos.length > 0)?[_c('span',[_vm._v(" "+_vm._s(tabledata.tied)+" "+_vm._s(tabledata.pos)+" ")])]:_vm._e()],2):_c('td',{staticClass:"noPos"},[_c('span',[_vm._v(".")])]),(_vm.RTCDS > 0 && tabledata.pos !== 'CUT')?_c('td',[(
                  tabledata.oom_pos.length > 0 && tabledata.oom_pos !== '0'
                )?_c('span',{staticClass:"scores-pos",class:{
                  up: _vm.table.oom_diff_pos > 0,
                  down: _vm.table.oom_diff_pos < 0
                }},[_vm._v(" "+_vm._s(_vm.value.oom_pos)+" "),(_vm.table.oom_diff_pos > 0)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'arrow-up']}}):_vm._e(),(_vm.table.oom_diff_pos < 0)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'arrow-down']}}):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.home == true || _vm.isMobile(true))?[(
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    _vm.team === 'N'
                )?_c('td',{staticClass:"PlayerName",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down'
                }},[_c('a',{staticClass:"homeLinkPlayer",attrs:{"href":'/player-profiles/' + tabledata.member_no}},[_vm._v(" "+_vm._s(tabledata.name)+" "),(tabledata.pro_ind == 'Am')?_c('span',[_vm._v("(A)")]):_vm._e()]),(tabledata.sponsored === 'Y' && _vm.home !== true)?_c('span',[_c('img',{staticClass:"titliestLogo",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"}})]):_vm._e(),(_vm.showGender === 'Y')?_c('span',{staticClass:"multiDot-size-reportgender",class:{
                    female: tabledata.gender === 'F',
                    male: tabledata.gender === 'M'
                  }},[_vm._v("•")]):_vm._e()]):_vm._e(),(
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    _vm.team === 'Y'
                )?_c('td',{staticClass:"PlayerName",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down'
                }},[_vm._v(" "+_vm._s(tabledata.name)+" "),(tabledata.pro_ind == 'Am')?_c('span',[_vm._v("(A)")]):_vm._e(),(tabledata.sponsored === 'Y' && _vm.home !== true)?_c('span',[_c('img',{staticClass:"titliestLogo",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"}})]):_vm._e(),(_vm.showGender === 'Y')?_c('span',{staticClass:"multiDot-size-reportgender",class:{
                    female: tabledata.gender === 'F',
                    male: tabledata.gender === 'M'
                  }},[_vm._v("•")]):_vm._e()]):_vm._e()]:[(
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    _vm.team === 'N'
                )?_c('td',{staticClass:"PlayerName",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down'
                },on:{"click":function($event){_vm.playerCard(tabledata.id, (_vm.toggle = !_vm.toggle))}}},[_vm._v(" "+_vm._s(tabledata.name)+" "),(tabledata.pro_ind == 'Am')?_c('span',[_vm._v("(A)")]):_vm._e(),(tabledata.sponsored === 'Y' && _vm.home !== true)?_c('span',[_c('img',{staticClass:"titliestLogo",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"}})]):_vm._e(),(_vm.showGender === 'Y')?_c('span',{staticClass:"multiDot-size-reportgender",class:{
                    female: tabledata.gender === 'F',
                    male: tabledata.gender === 'M'
                  }},[_vm._v("•")]):_vm._e()]):_vm._e(),(
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    _vm.team === 'Y'
                )?_c('td',{staticClass:"PlayerName",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down'
                },on:{"click":function($event){_vm.playerCard(tabledata.id, (_vm.toggle = !_vm.toggle))}}},[_vm._v(" "+_vm._s(tabledata.name)+" "),(tabledata.pro_ind == 'Am')?_c('span',[_vm._v("(A)")]):_vm._e(),(tabledata.sponsored === 'Y' && _vm.home !== true)?_c('span',[_c('img',{staticClass:"titliestLogo",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"}})]):_vm._e(),(_vm.showGender === 'Y')?_c('span',{staticClass:"multiDot-size-reportgender",class:{
                    female: tabledata.gender === 'F',
                    male: tabledata.gender === 'M'
                  }},[_vm._v("•")]):_vm._e()]):_vm._e()],(_vm.data.multi_course === 'Y' && tabledata.pos !== 'CUT')?_c('td',[(_vm.data.multi_course === 'Y')?_c('span',{staticClass:"multiDot-size-report",class:tabledata['course_colour_R' + _vm.data.pft_round]},[_vm._v("•")]):_vm._e()]):_vm._e(),(tabledata.pos !== 'CUT')?_c('td',{staticClass:"mobHide",class:{ natNone: _vm.home === true }},[(tabledata.nationality === '')?_c('span'):_c('span',[_c('img',{staticClass:"flag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + tabledata.nationality))}})])]):_vm._e(),(
                _vm.data.score_type === 'P' ||
                  (_vm.data.tt_type === 'P' && _vm.data.stableford === 'N')
              )?[(tabledata.vspar < 0)?_c('td',{staticClass:"up"},[_vm._v(" "+_vm._s(tabledata.vspar)+" ")]):(tabledata.vspar > 0)?_c('td',{staticClass:"down"},[_vm._v(" "+_vm._s(tabledata.vspar)+" ")]):(tabledata.pos !== 'CUT')?_c('td',{class:{
                  nonScore: tabledata.vspar == 'RTD',
                  smallsize: tabledata.score == 'WDN'
                }},[_vm._v(" "+_vm._s(tabledata.vspar)+" ")]):_vm._e()]:_vm._e(),(
                _vm.data.score_type === 'P' ||
                  (_vm.data.tt_type === 'P' && _vm.data.stableford === 'Y')
              )?[(tabledata.pos !== 'CUT')?_c('td',[_vm._v(" "+_vm._s(tabledata.score)+" ")]):_vm._e()]:_vm._e(),(
                _vm.title.includes('Scoreboard') &&
                  tabledata.pos !== 'CUT' &&
                  _vm.data.stableford === 'Y'
              )?[_vm._l((_vm.range(1, _vm.roundsPlayed)),function(i,roundIt){return _c('td',{key:roundIt,staticClass:"mobnone hideOnMob"},[(tabledata['score_R' + i] > 0)?_c('span',{staticClass:"plus"},[_vm._v(_vm._s(tabledata["score_R" + i]))]):(tabledata['score_R' + i] < 0)?_c('span',{staticClass:"minus"},[_vm._v(" "+_vm._s(tabledata["score_R" + i])+" ")]):_c('span',{staticClass:"zero"},[_vm._v(" "+_vm._s(tabledata["score_R" + i])+" ")])])}),(tabledata.score_found === 'Y')?[(tabledata.vspar < 0 && _vm.roundsPlayed > 1)?_c('td',{},[_vm._v(" "+_vm._s(tabledata.score)+" ")]):(tabledata.vspar > 0 && _vm.roundsPlayed > 1)?_c('td',{},[_vm._v(" "+_vm._s(tabledata.score)+" ")]):(_vm.roundsPlayed > 1)?_c('td',[_vm._v(" "+_vm._s(tabledata.score)+" ")]):_vm._e()]:_vm._e()]:_vm._e(),(
                _vm.title.includes('Scoreboard') &&
                  tabledata.pos !== 'CUT' &&
                  _vm.data.stableford === 'N'
              )?[_vm._l((_vm.range(1, _vm.roundsPlayed)),function(i,roundIt){return _c('td',{key:roundIt,staticClass:"mobnone hideOnMob"},[(_vm.roundsPlayed === 1 && tabledata.vspar < 0)?_c('span',{staticClass:"up"},[_vm._v(_vm._s(tabledata.score))]):(_vm.roundsPlayed === 1 && tabledata.vspar > 0)?_c('span',{staticClass:"down"},[_vm._v(_vm._s(tabledata.score))]):(_vm.roundsPlayed === 1)?_c('span',[_vm._v(_vm._s(tabledata.score))]):(tabledata['vspar_R' + i] < 0)?_c('span',{class:_vm.up},[_vm._v(_vm._s(tabledata["score_R" + i]))]):(tabledata['vspar_R' + i] > 0)?_c('span',{class:_vm.down},[_vm._v(_vm._s(tabledata["score_R" + i]))]):_c('span',{class:{
                    nonScore: tabledata.vspar == 'RTD',
                    smallsize: tabledata['score_R' + i] == 'WDN'
                  }},[_vm._v(_vm._s(tabledata["score_R" + i]))])])}),(tabledata.score_found === 'Y')?[(tabledata.vspar < 0 && _vm.roundsPlayed > 1)?_c('td',{staticClass:"up"},[_vm._v(" "+_vm._s(tabledata.score)+" ")]):(tabledata.vspar > 0 && _vm.roundsPlayed > 1)?_c('td',{staticClass:"down"},[_vm._v(" "+_vm._s(tabledata.score)+" ")]):(_vm.roundsPlayed > 1)?_c('td',{class:{ smallsize: tabledata.score == 'WDN' }},[_vm._v(" "+_vm._s(tabledata.score)+" ")]):_vm._e()]:_vm._e()]:(
                _vm.data.round_head == 'Latest Scores' ||
                  tabledata.score_found == 'Y'
              )?[_vm._l((_vm.range(1, _vm.roundsPlayed)),function(i,roundIt){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(tabledata.pos !== 'CUT'),expression:"tabledata.pos !== 'CUT'"}],key:roundIt,staticClass:"mobnone"},[(_vm.roundsPlayed === 1 && tabledata.vspar < 0)?_c('span',{staticClass:"up"},[_vm._v(_vm._s(tabledata.score))]):(_vm.roundsPlayed === 1 && tabledata.vspar > 0)?_c('span',{staticClass:"down"},[_vm._v(_vm._s(tabledata.score))]):(_vm.roundsPlayed === 1)?_c('span',[_vm._v(_vm._s(tabledata.score))]):(tabledata['vspar_R' + i] < 0)?_c('span',{class:_vm.up},[_vm._v(_vm._s(tabledata["score_R" + i]))]):(tabledata['vspar_R' + i] > 0)?_c('span',{class:_vm.down},[_vm._v(_vm._s(tabledata["score_R" + i]))]):_c('span',{class:{ nonScore: tabledata.vspar == 'RTD' }},[_vm._v(_vm._s(tabledata["score_R" + i]))])])}),(tabledata.score_found === 'Y')?[(tabledata.vspar < 0 && _vm.roundsPlayed > 1)?_c('td',{staticClass:"up"},[_vm._v(" "+_vm._s(tabledata.score)+" ")]):(tabledata.vspar > 0 && _vm.roundsPlayed > 1)?_c('td',{staticClass:"down"},[_vm._v(" "+_vm._s(tabledata.score)+" ")]):(_vm.roundsPlayed > 1)?_c('td',[_vm._v(" "+_vm._s(tabledata.score)+" ")]):_vm._e()]:_vm._e()]:_vm._e(),(
                _vm.data.round_head == 'Latest Scores' ||
                  tabledata.score_found == 'N'
              )?[(_vm.home === false && tabledata.pos !== 'CUT')?_c('td',{staticStyle:{"color":"#000"}},[(tabledata.holes && tabledata.holes.length > 0)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(tabledata.holes != '18'),expression:"tabledata.holes != '18'"}]},[_vm._v(_vm._s(tabledata.holes)+" "),(tabledata['tee_R' + _vm.data.pft_round] > 1)?_c('span',[_vm._v("*")]):_vm._e(),(_vm.data.rts[1] == 'S')?_c('span',{staticClass:"strokesColour"},[_vm._v(_vm._s(tabledata['strokes_R' + _vm.data.pft_round].split(",")[1]))]):_vm._e()]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(tabledata.holes == '18'),expression:"tabledata.holes == '18'"}]},[_vm._v("F")])]:[_vm._v(" "+_vm._s(tabledata["time_R" + _vm.data.pft_round])+" ")]],2):_vm._e()]:_vm._e(),(
                tabledata.playerno !== 'Cat' &&
                  tabledata.playerno !== 'EOF' &&
                  tabledata.pos !== 'CUT'
              )?_c('td',[(tabledata.profile == 'Y')?_c('a',{attrs:{"href":'/player-profiles/' + tabledata.member_no,"target":"_blank"}},[_c('span',{staticClass:"iconRight"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'chevron-right']}})],1)]):_vm._e()]):_vm._e()]:_vm._e()],2),(index === _vm.clickedRow && _vm.toggle == true)?_c('tr',{key:index + Math.random(),staticClass:"tr-kids",on:{"click":function($event){$event.stopPropagation();return _vm.rowClicked(_vm.rowClicked === -1)}}},[_c('td',{attrs:{"id":"reportCard","colspan":"11"}},[_c('PlayerReportCard',{attrs:{"playerData":_vm.playerData,"playerDataTeam":_vm.playerDataTeam,"data":_vm.data,"config":_vm.config,"actualRound":_vm.data.pft_round,"roundsPlayed":_vm.data.pft_round,"scoreType":_vm.data.scores_type,"team":_vm.team,"courses":_vm.courses,"stablford":_vm.data.stableford,"rts":_vm.data.rts[0]}})],1)]):_vm._e()]})],2)]),(_vm.home === true)?_c('div',[_c('b-container',{staticClass:"homeFullWidth"},[_c('b-row',{staticClass:"homeLeaderHome FUllLeaderboard"},[_c('b-col',{staticClass:"DrawCol"},[_c('b-nav-item',{staticClass:"fullNav",attrs:{"to":{
              name: 'reports-page',
              query: {
                url:
                  'https://info.letaccess.com/tic/tmdraw.cgi?tourn=' +
                  _vm.code +
                  '~season=' +
                  _vm.season +
                  '~round=' +
                  _vm.data.round +
                  '~',
                code: _vm.code,
                id: _vm.season,
                title: 'Round ' + _vm.data.round + ' Draw'
              }
            }}},[_c('h3',{staticClass:"drawtext"},[_vm._v("Draw")])])],1),_c('b-col',{staticClass:"LeadCol pulse"},[_c('b-nav-item',{staticClass:"fullNav",attrs:{"to":{
              name: 'reports-page',
              query: {
                url: this.report,
                id: _vm.season,
                code: _vm.code,
                title: _vm.title
              }
            }}},[_c('h3',{staticClass:"FullLeaderboard"},[_vm._v("Full Leaderboard")])])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }